/* Globals */

:root {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600;
  --brand-dark: #000;
  --brand-gray: #d1d3d4;
  --brand-light: #fff;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9;
  --brand-success: #59d930;
  --brand-warning: #d9b030;
  --brand-danger: #d93059;
}

html,
body {
  background-color: var(--brand-light);
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: var(--brand-primary);
}

inspect {
  border: thin solid var(--brand-danger);
}

.Landing {
  background-color: var(--brand-light);
}

.Landing .jumbotron {
  opacity: 0.92;
  background-color: var(--brand-light);
}

/* Bootstrap Overrides */

.bg-primary {
  background-color: var(--brand-primary) !important;
}

.bg-secondary {
  background-color: var(--brand-gray) !important;
  color: var(--brand-dark);
}

.border-info {
  border-color: var(--brand-primary) !important;
}

.btn-primary {
  background-color: var(--brand-primary);
  border-color: var(--brand-primary);
}

.btn-outline-primary {
  border-color: var(--brand-primary);
  color: var(--brand-primary);
}

.btn-outline-primary:hover {
  background-color: var(--brand-primary);
  color: var(--brand-light);
}

.btn-nav {
  background-color: var(--brand-gray);
  color: var(--brand-dark);
}

.btn-nav:hover {
  background-color: var(--brand-light);
}

.btn-primary:hover {
  background-color: var(--brand-dark);
}

.navbar-dark,
.bg-footer {
  background-color: var(--brand-primary);
}

.bg-footer a {
  color: var(--brand-light);
  text-decoration: underline;
}

/* Avatar */

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Scaffolding */

.panel-landing {
  max-width: 50em;
  margin: auto;
}

.text-right {
  text-align: right;
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css');

/* Adjust sticky top for profile */

.sticky-top-profile {
  top: 115px;
}
