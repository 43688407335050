@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css);
/* Globals */

:root {
  /* Primary CityCoins Branding */
  --brand-primary: #3059d9;
  --brand-accent: #f7c600;
  --brand-dark: #000;
  --brand-gray: #d1d3d4;
  --brand-light: #fff;
  /* Triadic and Complementary Variations */
  --brand-info: #30aed9;
  --brand-success: #59d930;
  --brand-warning: #d9b030;
  --brand-danger: #d93059;
}

html,
body {
  background-color: #fff;
  background-color: var(--brand-light);
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: #3059d9;
  color: var(--brand-primary);
}

inspect {
  border: thin solid #d93059;
  border: thin solid var(--brand-danger);
}

.Landing {
  background-color: #fff;
  background-color: var(--brand-light);
}

.Landing .jumbotron {
  opacity: 0.92;
  background-color: #fff;
  background-color: var(--brand-light);
}

/* Bootstrap Overrides */

.bg-primary {
  background-color: #3059d9 !important;
  background-color: var(--brand-primary) !important;
}

.bg-secondary {
  background-color: #d1d3d4 !important;
  background-color: var(--brand-gray) !important;
  color: #000;
  color: var(--brand-dark);
}

.border-info {
  border-color: #3059d9 !important;
  border-color: var(--brand-primary) !important;
}

.btn-primary {
  background-color: #3059d9;
  background-color: var(--brand-primary);
  border-color: #3059d9;
  border-color: var(--brand-primary);
}

.btn-outline-primary {
  border-color: #3059d9;
  border-color: var(--brand-primary);
  color: #3059d9;
  color: var(--brand-primary);
}

.btn-outline-primary:hover {
  background-color: #3059d9;
  background-color: var(--brand-primary);
  color: #fff;
  color: var(--brand-light);
}

.btn-nav {
  background-color: #d1d3d4;
  background-color: var(--brand-gray);
  color: #000;
  color: var(--brand-dark);
}

.btn-nav:hover {
  background-color: #fff;
  background-color: var(--brand-light);
}

.btn-primary:hover {
  background-color: #000;
  background-color: var(--brand-dark);
}

.navbar-dark,
.bg-footer {
  background-color: #3059d9;
  background-color: var(--brand-primary);
}

.bg-footer a {
  color: #fff;
  color: var(--brand-light);
  text-decoration: underline;
}

/* Avatar */

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Scaffolding */

.panel-landing {
  max-width: 50em;
  margin: auto;
}

.text-right {
  text-align: right;
}

/* Adjust sticky top for profile */

.sticky-top-profile {
  top: 115px;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group label {
  position: absolute;
  top: 0;
  font-size: 15px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group {
    display: flex;
    flex-direction: column-reverse;
  }

  .form-label-group label {
    position: static;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}


